@import '../../../../projects/ui/src/lib/scss/base/mixins';
@import '../../../../projects/ui/src/lib/scss/utils/u-grid';

app-overview-container {
    .__container-desktop {
        @extend .u-grid-cols-10;

        aside {
            grid-column: 1 / 4;
        }

        main {
            grid-column: 4 / 11;
        }
    }

    // override padding on this page
    app-lineup-offer .__offer-main {
        padding-bottom: 32px !important;
    }
}
